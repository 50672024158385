// extracted by mini-css-extract-plugin
export var onlineMarketplaceAchievementsSection = "t_cJ";
export var onlineMarketplaceCardBorderSection = "t_cr";
export var onlineMarketplaceCardRowIconsSection = "t_cx";
export var onlineMarketplaceClientQuotesSection = "t_cK";
export var onlineMarketplaceCycleDevSection = "t_cs";
export var onlineMarketplaceCycleDevSection__testimonial = "t_ct";
export var onlineMarketplaceDomainExpertiseSection = "t_cv";
export var onlineMarketplaceExceptionSection = "t_cw";
export var onlineMarketplaceFeaturedCaseSection = "t_cy";
export var onlineMarketplaceFeaturesSection = "t_cB";
export var onlineMarketplaceFeaturesSection__box = "t_cC";
export var onlineMarketplaceRequirementSection = "t_cD";
export var onlineMarketplaceSuccessStoriesSection = "t_cG";
export var onlineMarketplaceSuccessfulSection = "t_cz";
export var onlineMarketplaceTechStackSection = "t_cF";
export var onlineMarketplaceWhyHireSection = "t_cH";